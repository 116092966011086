<template>
  <div class="h-full overflow-auto bg-neutral-100">
    <div class="">
      <!-- content -->
      <div class="mx-auto mb-5 mt-5 max-w-5xl px-2">
        <div
          class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
        >
          <!-- Card Content goes here -->
          <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
            <div>
              <h1 class="text-2xl font-extrabold">
                {{ appSettingsStore.applicationTitle }}
                <span class="font-semibold">({{ appSettingsStore.pageTitleBase }})</span>
              </h1>
              <h2 class="mt-2 text-xl text-neutral-500 md:mt-1">
                <span class="font-semibold">Welcome, {{ userRef.firstName }}</span>
              </h2>
              <!-- <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <p>
                  SMART is an online system that the Nevada Division of Environmental Protection's Bureau of Sustainable
                  Materials Management uses to collect recycling and disposal data.
                </p>
              </div> -->
            </div>
            <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
              <LogoutButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign Out" />
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto mb-5 max-w-5xl px-2" v-if="solidWasteNavItems.items?.length > 0">
        <div class="mx-3 sm:mx-5 md:mx-8 xl:mx-0">
          <h2 class="text-sm font-medium text-neutral-500">Solid Waste</h2>
          <ul role="list" class="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4 lg:gap-6">
            <template v-for="item in solidWasteNavItems.items" :key="item.name">
              <SkeletonButton v-if="solidWasteNavItems.isAuthPending" class="col-span-1" />
              <li class="col-span-1 flex rounded-md shadow-sm" v-if="solidWasteNavItems.isAuthorized">
                <router-link
                  :to="{ name: item.name }"
                  class="flex flex-1 items-start justify-between rounded-md border border-neutral-200 bg-white text-neutral-900 transition-all hover:border-sky-200 hover:bg-sky-100 hover:text-sky-900 focus:border-sky-200 focus:bg-sky-100 focus:text-sky-900"
                >
                  <div class="flex-1 px-4 py-2 text-sm">
                    <div class="font-medium">{{ item.label }}</div>
                    <p class="text-neutral-500">
                      {{ item.description }}
                    </p>
                  </div>
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="mx-auto mb-5 max-w-5xl px-2" v-if="sustainabilityNavItems.items?.length > 0">
        <div class="mx-3 sm:mx-5 md:mx-8 xl:mx-0">
          <h2 class="text-sm font-medium text-neutral-500">Sustainability</h2>
          <ul role="list" class="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4 lg:gap-6">
            <template v-for="item in sustainabilityNavItems.items" :key="item.name">
              <SkeletonButton v-if="sustainabilityNavItems.isAuthPending" class="col-span-1" />
              <li class="col-span-1 flex rounded-md shadow-sm" v-if="sustainabilityNavItems.isAuthorized">
                <router-link
                  :to="{ name: item.name }"
                  class="flex flex-1 items-start justify-between rounded-md border border-neutral-200 bg-white text-neutral-900 transition-all hover:border-sky-200 hover:bg-sky-100 hover:text-sky-900 focus:border-sky-200 focus:bg-sky-100 focus:text-sky-900"
                >
                  <div class="flex-1 px-4 py-2 text-sm">
                    <div class="font-medium">{{ item.label }}</div>
                    <p class="text-neutral-500">
                      {{ item.description }}
                    </p>
                  </div>
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
      <MdrnAttribution />
    </footer>
  </div>
</template>

<script lang="ts">
import { InitialBadge } from "@modernary/mui/components/badge";
import { BaseButton } from "@modernary/mui/components/button";
import {
  AppNavItem,
  AppNavItemGroup,
  AppNavItemGroupConfig,
  MdrnAttribution,
  NoneFoundFlag,
  SkeletonButton,
  useAppNav,
  useAppSettingsStore,
} from "@modernary/mui/components/common";
import { LoginButton, LogoutButton, RegisterButton, useUser } from "@modernary/mui/utils/authentication";
import { Authorize, useAuthorization } from "@modernary/mui/utils/authorization";
import { enumsCopy } from "@/constants/enums";
import { routeNames } from "@/routes";

import { ref, computed, onMounted, watch, defineComponent } from "vue";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/vue/20/solid";
import { sortByAlphabeticalByField } from "@modernary/mui/utils/helpers";

export default defineComponent({
  name: "HomeInternal",
  setup() {
    const { userRef } = useUser();
    const appSettingsStore = useAppSettingsStore();
    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();

    const { navigationItems, userNavigationItems } = useAppNav();

    const solidWasteNavItems = computed(() => {
      const solidWaste = navigationItems?.find((x) => x.label === "Solid Waste");
      const items = solidWaste?.items;
      if (!items || !solidWaste.isAuthorized) {
        return { items: [], isAuthorized: ref(false), isAuthPending: ref(false) };
      }
      return {
        items: items,
        isAuthorized: solidWaste.isAuthorized,
        isAuthPending: solidWaste.isAuthPending,
      };
    });

    const sustainabilityNavItems = computed(() => {
      const sustainability = navigationItems?.find((x) => x.label === "Sustainability");
      const items = sustainability?.items;
      if (!items || !sustainability.isAuthorized) {
        return { items: [], isAuthorized: ref(false), isAuthPending: ref(false) };
      }
      return {
        items: items,
        isAuthorized: sustainability.isAuthorized,
        isAuthPending: sustainability.isAuthPending,
      };
    });

    return {
      userRef,
      routeNames,
      BaseButton,
      appSettingsStore,
      navigationItems,
      solidWasteNavItems,
      sustainabilityNavItems,
    };
  },
  components: {
    BaseButton,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
    LogoutButton,
    Authorize,
    SkeletonButton,
  },
});
</script>
