import { MaterialCategories, NevadaCounties, enums, enumsCopy } from "@/constants/enums";
import {
  Diversions,
  IRecyclingReport,
  IRecyclingReportNew,
  RecyclingCategory,
  RecyclingCategoryType,
  RecyclingCounty,
} from "@/views/recyclingReport/recyclingReport";
import { sortByAlphabeticalByField } from "@modernary/mui/utils/helpers";
import { ref, reactive, unref, Ref } from "vue";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  filterToVisisble,
};

const newRecyclingCounty = (countyId: string) => {
  const result: RecyclingCounty = {
    county: countyId as NevadaCounties,
    totalTons: 0, //ref(0),
    materialCategories: new Array<RecyclingCategory>(),
    materialCategoriesMap: new Map<string, RecyclingCategory>(), // Only used while building the data, removed before returning.
    availableMaterialCategories: reactive<{ [key: string]: boolean }>({}),
    materialCategoriesArray: new Array<{ label: string; value: string }>(),
    hasAvailableCategories: ref(true),
    categorySelectionRef: ref(null),
  };
  for (let key of enums.materialCategories.enums.keys()) {
    result.availableMaterialCategories[key] = true;
  }
  return result;
};

const newRecyclingCategory = (categoryId: string) => {
  return {
    category: categoryId as MaterialCategories,
    totalTons: 0, //ref(0),
    types: new Array<RecyclingCategoryType>(),
  };
};

const newDivertedCounty = (countyId: string) => {
  return {
    county: countyId as NevadaCounties,
    totalTons: 0, //ref(0),
    materialCategories: new Array<{
      id: number;
      description: string;
      destination: string;
      tonnage: number;
      key: number;
    }>(),
  };
};

function addAuthRequestFields(entity: IRecyclingReport) {
  //TODO:TS
  entity._authRequestFields = ["id", "organizationId", "status", "type", "year"];
  return entity;
}

function getInitialData() {
  const result: IRecyclingReport = {
    type: "RecyclingReport",
    period: "Annual",
    status: "Draft",
    diversions: { counties: [] } as unknown as Diversions, //TODO: TS
    counties: [],
  } as unknown as IRecyclingReport; //TODO: TS
  return addAuthRequestFields(result);
}

function getCountiesWithNullOption() {
  return enumsCopy.nevadaCounties().toDropDown(); //enumToDropdown(enumsCopy.nevadaCounties());
}

function filterToVisisble<T extends Array<{ label: string; value: string }>>(
  array: T,
  map: { [key: string]: boolean },
) {
  return array.filter((x) => map[x.value]);
}

//TODO - consolidate
function responseAdapter<T extends IRecyclingReport | IRecyclingReportNew>(response: { data: T }) {
  const allMaterialCategoriesArray = enums.materialCategories.toDropDown(); // enumToDropdown(enums.materialCategories);
  // Materials come to us completely flattened. We need to build a hierarchy for the UI.
  let countyMap: { [key: string]: RecyclingCounty } = {};
  let counties: RecyclingCounty[] = [];
  const rollUp: IRecyclingReport["rollUp"] = {
    totalTons: 0,
    categories: {},
  };
  const availableCountiesArray = getCountiesWithNullOption();
  const availableCounties: { [key: string]: boolean } = {};
  availableCountiesArray.forEach((x) => (availableCounties[x.value] = true));
  if (isFullRecyclingReport(response.data)) {
    response.data.recycledMaterials?.forEach((item) => {
      let county = countyMap[item.county];
      if (!county) {
        county = countyMap[item.county] = newRecyclingCounty(item.county);
        counties.push(county);
      }
      availableCounties[county.county] = false;

      let category = county.materialCategoriesMap?.get(item.category);
      if (!category) {
        category = newRecyclingCategory(item.category);
        county.materialCategoriesMap?.set(item.category, category);
        county.availableMaterialCategories[category.category] = false;
        county.materialCategories.push(category);
      }
      category.types.push({
        id: item.id,
        destination: item.destination,
        companyName: item.companyName,
        streetAddress: item.streetAddress,
        city: item.city,
        state: item.state,
        country: item.country,
        tonnage: item.tonnage,
        type: item.type,
        key: item.id,
        isDuplicated: item.isDuplicated,
      });

      let categoryRollUp = rollUp.categories[item.category];
      if (!categoryRollUp) {
        categoryRollUp = rollUp.categories[item.category] = {
          category: item.category,
          totalTons: 0,
        };
      }

      category!.totalTons += item.tonnage;
      county.totalTons += item.tonnage;
      rollUp.totalTons += item.tonnage;
      categoryRollUp.totalTons += item.tonnage;
    });
  }

  counties.forEach((county) => {
    county.materialCategoriesArray = filterToVisisble(allMaterialCategoriesArray, county.availableMaterialCategories);
    county.hasAvailableCategories.value = county.materialCategoriesArray.length > 0; // hasVisibleOption(county.availableMaterialCategories);
    delete county.materialCategoriesMap;
  });

  sortByAlphabeticalByField(counties, "county");

  response.data.rollUp = rollUp;
  response.data.counties = counties;

  // Reset vars.

  const diversions: Diversions = {
    totalTons: 0,
    countyMap: {},
    counties: [],
    availableCountiesArray: getCountiesWithNullOption(),
    availableCounties: {},
  };
  diversions.availableCountiesArray.forEach((x) => (diversions.availableCounties[x.value] = true));

  let countyMapForDiversions = diversions.countyMap;
  let countiesForDiversions = diversions.counties;
  if (isFullRecyclingReport(response.data)) {
    response.data.divertedMaterials?.forEach((item) => {
      let county = countyMapForDiversions[item.county];
      if (!county) {
        county = countyMapForDiversions[item.county] = newDivertedCounty(item.county);
        countiesForDiversions.push(county);
        diversions.availableCounties[county.county] = false;
      }
      county.materialCategories.push({
        id: item.id,
        description: item.description,
        destination: item.destination,
        tonnage: item.tonnage,
        key: item.id,
      });
      county.totalTons += item.tonnage;
      diversions.totalTons += item.tonnage;
    });
  }

  sortByAlphabeticalByField(diversions.counties, "county");

  response.data.diversions = diversions;
  response.data.countiesMap = availableCounties;
  response.data.allCounties = availableCountiesArray;
  response.data.availableCountiesArray = availableCountiesArray.filter((x) => availableCounties[x.value]);
  response.data.allMaterialCategoriesArray = allMaterialCategoriesArray;

  response.data.newRecyclingCounty = newRecyclingCounty;
  response.data.newRecyclingCategory = newRecyclingCategory;
  response.data.newDivertedCounty = newDivertedCounty;

  if (isFullRecyclingReport(response.data)) {
    addAuthRequestFields(response.data);
  }

  return response.data;
}

function isFullRecyclingReport(
  recyclingReport: IRecyclingReport | IRecyclingReportNew,
): recyclingReport is IRecyclingReport {
  return (recyclingReport as IRecyclingReport).id !== undefined;
}

function requestAdapter() {}
