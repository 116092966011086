<template>
  <div class="h-full overflow-auto bg-neutral-100">
    <div class="">
      <!-- content -->
      <div class="mx-auto mt-10 grid grid-cols-1 gap-10 xl:grid-cols-2">
        <!-- Organizations -->
        <div class="3xl:pl-8">
          <div class="mx-0 sm:mx-5 md:mx-8 xl:mr-0 3xl:mx-auto 3xl:max-w-5xl">
            <h2 class="mb-2 pl-2 text-lg font-medium leading-6 text-neutral-900">Recycling Reports</h2>
            <div class="mb-4 bg-white shadow sm:mb-5 sm:rounded-lg md:mb-8 xl:mx-auto xl:max-w-5xl">
              <div class="border-b border-neutral-200 px-4 py-5 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-neutral-900">My Organizations</h3>
              </div>
              <div
                v-if="isGetOrganizationsSuccess && organizations?._items?.length === 0"
                class="bg-neutral-50 p-10 sm:rounded-b-lg"
              >
                <NoneFoundFlag />
              </div>
              <div v-if="isGetOrganizationsPending">
                <div class="flex animate-pulse space-x-4">
                  <div class="flex flex-1 items-center px-4 py-4 sm:px-6">
                    <div class="flex min-w-0 flex-1 items-center">
                      <div class="flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <div class="pr-8 text-sm font-medium text-neutral-800 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-300"></div>
                          </div>
                          <div class="mt-2 pr-8 text-sm text-neutral-500 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-200"></div>
                          </div>
                        </div>
                        <div class="hidden justify-center md:flex md:flex-col">
                          <div class="pr-8 text-sm text-neutral-900 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-300"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="h-6 w-6 rounded bg-neutral-200"></div>
                    </div>
                  </div>
                </div>
              </div>
              <ul role="list" class="divide-y divide-neutral-200">
                <li v-for="org in organizations?._items" :key="org.id" class="">
                  <router-link
                    :to="{ name: 'OrganizationView', params: { id: org.id } }"
                    class="group block hover:bg-neutral-100"
                  >
                    <div class="flex items-center px-4 py-4 sm:px-6">
                      <div class="flex min-w-0 flex-1 items-center">
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p class="truncate text-sm font-medium text-neutral-800">
                              {{ org.name }}
                            </p>
                            <p class="mt-2 flex items-center text-sm text-neutral-500">
                              <!-- <EnvelopeIcon
                              class="mr-1.5 h-5 w-5 flex-shrink-0 text-neutral-400"
                              aria-hidden="true"
                            /> -->
                              <span class="truncate">{{
                                recyclingCategories.get(org.recyclingCategory)?.description
                              }}</span>
                            </p>
                          </div>
                          <div class="hidden justify-center md:flex md:flex-col">
                            <p class="text-sm text-neutral-900">
                              <span v-if="org.currentReport"
                                >{{ (org.currentReport as any).year }} Recycling Report
                                <RecyclingReportStatusBadge
                                  :label-id="(org.currentReport as any).status"
                                  size="sm"
                                  class="ml-2"
                                ></RecyclingReportStatusBadge
                              ></span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          class="z-0 h-6 w-6 text-neutral-300 transition-transform ease-in group-hover:translate-x-1 group-hover:text-neutral-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mb-4 mt-4 bg-white shadow sm:my-5 sm:rounded-lg md:my-8 xl:mx-auto xl:max-w-5xl">
              <div class="mx-auto max-w-xl">
                <div class="px-4 pb-7 pt-6">
                  <div class="text-center">
                    <h2 class="text-lg font-medium text-neutral-900">Request Organization Access</h2>
                    <p class="mt-1 text-sm text-neutral-500">
                      Do you want to submit recycling reports for a company or an agency?<br />
                      Your user account must be linked to the organization. To get started, simply request access to an
                      organization below.
                    </p>
                  </div>
                  <FormKit
                    type="form"
                    id="orgFormId"
                    form-class="$reset mt-6 items-baseline sm:flex"
                    :value="rrAccessRequest"
                    v-model="rrAccessRequest"
                    @submit="handleSubmit"
                    :actions="false"
                  >
                    <template #default="{ state: formState }">
                      <FormKit
                        type="text"
                        label="Organization Name"
                        name="requestText"
                        placeholder="Organization Name"
                        validation="required:trim|length:0,255"
                        :sections-schema="noLabel"
                        outer-class="flex-auto"
                        :key="formSubmissionCountRef"
                      />
                      <BaseButton
                        variant="valid"
                        label="Request Access"
                        class="mt-3 h-10 w-full whitespace-nowrap sm:ml-4 sm:mt-0 sm:w-auto"
                        :disabled="!formState.valid"
                        @click="$formkit.submit('orgFormId')"
                      >
                        <template #iconBefore v-if="formState.loading">
                          <div
                            class="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          ></div>
                        </template>
                      </BaseButton>
                    </template>
                  </FormKit>
                  <div class="text-center">
                    <p class="mt-6 text-sm text-neutral-500">
                      You will be notified after your request is reviewed and access is granted.
                    </p>
                  </div>
                </div>
                <!-- <div class="mt-10">
                        <h3 class="text-sm font-medium text-neutral-500">
                          Team members previously added to projects
                        </h3>
                      </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- Facilities -->
        <div class="3xl:pr-8">
          <div class="mx-0 sm:mx-5 md:mx-8 xl:ml-0 3xl:mx-auto 3xl:max-w-5xl">
            <h2 class="mb-2 pl-2 text-lg font-medium leading-6 text-neutral-900">Disposal Reports</h2>
            <div class="mb-4 bg-white shadow sm:mb-5 sm:rounded-lg md:mb-8 xl:mx-auto xl:max-w-5xl">
              <div class="border-b border-neutral-200 px-4 py-5 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-neutral-900">My Facilities</h3>
              </div>
              <div
                v-if="isGetFacilitySitesSuccess && facilitySites?._items?.length === 0"
                class="bg-neutral-50 p-10 sm:rounded-b-lg"
              >
                <NoneFoundFlag />
              </div>
              <div v-if="isGetFacilitySitesPending">
                <div class="flex animate-pulse space-x-4">
                  <div class="flex flex-1 items-center px-4 py-4 sm:px-6">
                    <div class="flex min-w-0 flex-1 items-center">
                      <div class="flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <div class="pr-8 text-sm font-medium text-neutral-800 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-300"></div>
                          </div>
                          <div class="mt-2 pr-8 text-sm text-neutral-500 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-200"></div>
                          </div>
                        </div>
                        <div class="hidden justify-center md:flex md:flex-col">
                          <div class="pr-8 text-sm text-neutral-900 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-300"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="h-6 w-6 rounded bg-neutral-200"></div>
                    </div>
                  </div>
                </div>
              </div>
              <ul role="list" class="divide-y divide-neutral-200">
                <li v-for="facility in facilitySites?._items" :key="facility.id" class="">
                  <router-link
                    :to="{ name: 'FacilitySiteView', params: { id: facility.id } }"
                    class="group block hover:bg-neutral-100"
                  >
                    <div class="flex items-center px-4 py-4 sm:px-6">
                      <div class="flex min-w-0 flex-1 items-center">
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p class="truncate text-sm font-medium text-neutral-800">
                              {{ facility.name }}
                            </p>
                            <p class="mt-2 flex items-center text-sm text-neutral-500">
                              <!-- <EnvelopeIcon
                              class="mr-1.5 h-5 w-5 flex-shrink-0 text-neutral-400"
                              aria-hidden="true"
                            /> -->
                              <span class="truncate">{{ facilitySiteTypes.get(facility.type)?.description }}</span>
                            </p>
                          </div>
                          <div class="hidden justify-center md:flex md:flex-col">
                            <p class="text-sm text-neutral-900">
                              <!-- <span v-if="facility.currentReport"
                                  >{{ facility.currentReport.year }} Disposal Report
                                  <DisposalReportStatusBadge
                                    :label-id="facility.currentReport.status"
                                    size="sm"
                                    class="ml-2"
                                  ></DisposalReportStatusBadge
                                ></span> -->
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          class="z-0 h-6 w-6 text-neutral-300 transition-transform ease-in group-hover:translate-x-1 group-hover:text-neutral-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mb-4 mt-4 bg-white shadow sm:my-5 sm:rounded-lg md:my-8 xl:mx-auto xl:max-w-5xl">
              <div class="mx-auto max-w-xl">
                <div class="px-4 pb-7 pt-6">
                  <div class="text-center">
                    <h2 class="text-lg font-medium text-neutral-900">Request Facility Access</h2>
                    <p class="mt-1 text-sm text-neutral-500">
                      Do you want to submit disposal reports for a facility?<br />
                      Your user account must be linked to the facility. To get started, simply request access to a
                      facility below.
                    </p>
                  </div>
                  <FormKit
                    type="form"
                    id="facFormId"
                    form-class="$reset mt-6 items-baseline sm:flex"
                    :value="drAccessRequest"
                    v-model="drAccessRequest"
                    @submit="handleSubmit"
                    :actions="false"
                  >
                    <template #default="{ state: formState }">
                      <FormKit
                        type="text"
                        label="Facility Name"
                        name="requestText"
                        placeholder="Facility Name"
                        validation="required:trim|length:0,255"
                        :sections-schema="noLabel"
                        outer-class="flex-auto"
                        :key="formSubmissionCountRef"
                      />
                      <BaseButton
                        variant="valid"
                        label="Request Access"
                        class="mt-3 h-10 w-full whitespace-nowrap sm:ml-4 sm:mt-0 sm:w-auto"
                        :disabled="!formState.valid"
                        @click="$formkit.submit('facFormId')"
                      >
                        <template #iconBefore v-if="formState.loading">
                          <div
                            class="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          ></div>
                        </template>
                      </BaseButton>
                    </template>
                  </FormKit>
                  <div class="text-center">
                    <p class="mt-6 text-sm text-neutral-500">
                      You will be notified after your request is reviewed and access is granted.
                    </p>
                  </div>
                </div>
                <!-- <div class="mt-10">
                <h3 class="text-sm font-medium text-neutral-500">
                  Team members previously added to projects
                </h3>
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="xl:col-span-2">
          <div class="mx-0 sm:mx-5 md:mx-8 xl:ml-0 3xl:mx-auto 3xl:max-w-5xl">
            <div class="mb-4 mt-4 bg-white shadow sm:my-5 sm:rounded-lg md:my-8 xl:mx-auto xl:max-w-2xl">
              <div class="mx-auto max-w-xl">
                <div class="px-4 pb-7 pt-6">
                  <div class="text-center">
                    <h2 class="text-lg font-medium text-neutral-900">Questions?</h2>
                    <p class="mt-1 text-sm text-neutral-500">
                      <span class="text-neutral-700">Kayla Alm</span><br />
                      <span class="leading-loose">
                        <EnvelopeIcon class="mr-2 inline-block h-4 w-4" /><a href="mailto:kalm@ndep.nv.gov"
                          >kalm@ndep.nv.gov</a
                        >
                      </span>
                      <br />
                      <PhoneIcon class="mr-2 inline-block h-4 w-4" /><a href="tel:+1-775-687-9467">(775) 687-9467</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
      <MdrnAttribution />
    </footer>
  </div>
</template>

<script lang="ts">
import { InitialBadge } from "@modernary/mui/components/badge";
import { BaseButton } from "@modernary/mui/components/button";
import { MdrnAttribution, NoneFoundFlag, SkeletonButton, useAppNav } from "@modernary/mui/components/common";
import { useApi } from "@modernary/mui/utils/api";
import { LoginButton, RegisterButton, useUser } from "@modernary/mui/utils/authentication";
import { useAuthorization } from "@modernary/mui/utils/authorization";
import { noLabelSchema, sortByAlphabeticalByField } from "@modernary/mui/utils/helpers";
import RecyclingReportStatusBadge from "@/components/RecyclingReportStatusBadge.vue";
import DisposalReportStatusBadge from "@/components/DisposalReportStatusBadge.vue";
import { ref, computed, onMounted, watch, defineComponent } from "vue";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/vue/20/solid";
import { getOrganizations } from "@/views/organization/organizationApi";
import { getFacilitySites } from "@/views/facilitySite/facilitySiteApi";
import { createAccessRequest } from "@/views/accessRequest/accessRequestApi";
import { enums } from "@/constants/enums";
import { IOrganization } from "@/views/organization/organization";
import { IRecyclingReport } from "@/views/recyclingReport/recyclingReport";

// Home content for unauth'd users
export default defineComponent({
  name: "HomeExternal",
  setup() {
    const { userRef } = useUser();
    const noLabel = noLabelSchema();

    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();

    const isBureauAR = new AuthRequest();
    isBureauAR.roleName = "Bureau";

    const isHealthDistrictAR = new AuthRequest();
    isHealthDistrictAR.roleName = "HealthDistrict";

    const isBureau = ref(false);
    const isHealthDistrict = ref(false);
    authorizationClient
      .isAuthorizedArray([isBureauAR, isHealthDistrictAR])
      .then(([isBureauResult, isHealthDistrictResult]) => {
        isBureau.value = isBureauResult;
        isHealthDistrict.value = isHealthDistrictResult;
      }, handlePreempts);

    // Example usage of throttledAuthorize -
    // authorizationClient.throttledAuthorize(isBureauAR).then((result) => {
    //   isBureau.value = result?.isSuccess;
    // }, handlePreempts);

    // authorizationClient.throttledAuthorize(isHealthDistrictAR).then((result) => {
    //   isHealthDistrict.value = result?.isSuccess;
    // }, handlePreempts);

    const isBureauOrHd = computed(() => isBureau.value || isHealthDistrict.value);
    const isInternal = computed(
      () => Boolean(userRef.value?.claims?.some((x: any) => x.type === "role" && x.value === "Internal")), //TODO:TS
    );

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);
    const isExternalAuthenticated = computed(() => isAuthenticated?.value && !isInternal.value);
    const { recyclingCategories, facilitySiteTypes } = enums;

    type DashboardOrg = IOrganization & {
      currentReport: IRecyclingReport;
    };
    const {
      dataRef: organizations,
      // statusRef,
      // setStatus,
      exec: execGetOrganizations,
      isStatusPending: isGetOrganizationsPending,
      isStatusSuccess: isGetOrganizationsSuccess,
    } = useApi(getOrganizations, {
      // initialData: [],
      responseAdapter: (response) => {
        const reportingYear = new Date().getFullYear() - 1;

        response.data._items.forEach((org) => {
          //TODO:TS
          org.currentReport = org.recyclingReports._items.find((x: any) => x.year === reportingYear); //TODO:TS
        });

        return response.data;
      },
    });

    const {
      dataRef: facilitySites,
      // statusRef,
      // setStatus,
      exec: execGetFacilitySites,
      isStatusPending: isGetFacilitySitesPending,
      isStatusSuccess: isGetFacilitySitesSuccess,
    } = useApi(getFacilitySites, {
      // initialData: [],
      responseAdapter: (response) => {
        const reportingYear = new Date().getFullYear() - 1;

        // response.data._items.forEach((org) => {
        //   org.currentReport = org.recyclingReports._items.find((x) => x.year === reportingYear);
        // });

        return response.data;
      },
    });

    watch(
      isAuthenticated,
      (newValue) => {
        if (newValue) {
          execGetOrganizations({ expand: ["RecyclingReports"] });
          execGetFacilitySites();
        }
      },
      { immediate: true },
    );

    const rrAccessRequest = ref({ entityName: "", requestText: "" });
    rrAccessRequest.value.entityName = "Organization";

    const drAccessRequest = ref({ entityName: "", requestText: "" });
    drAccessRequest.value.entityName = "Facility Site";

    const formSubmissionCountRef = ref(0); //Used to "reset" the form field's state between submissions.
    const { exec: execCreateAccessRequest, isStatusPending } = useApi<any>(createAccessRequest, {
      initialData: { entityName: "", requestText: "" },
      responseAdapter: (response) => {
        if (response.data.wasRequestSubmitted) {
          rrAccessRequest.value.requestText = "";
          drAccessRequest.value.requestText = "";
          ++formSubmissionCountRef.value;
        }

        return response.data;
      },
    });

    const handleSubmit = (data: any) => {
      //TODO:TS
      return execCreateAccessRequest(data);
    };

    return {
      handleSubmit,
      noLabel,
      isExternalAuthenticated,
      isInternal,
      isBureauOrHd,
      isGetOrganizationsPending,
      isGetOrganizationsSuccess,
      organizations,
      userRef,
      isAuthenticated,
      drAccessRequest,
      rrAccessRequest,
      isStatusPending,
      formSubmissionCountRef,
      recyclingCategories,
      BaseButton,
      isGetFacilitySitesPending,
      isGetFacilitySitesSuccess,
      getFacilitySites,
      facilitySites,
      facilitySiteTypes,
    };
  },
  components: {
    RecyclingReportStatusBadge,
    BaseButton,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
  },
});
</script>
