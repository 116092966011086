<template>
  <router-view />
  <BaseNotifications></BaseNotifications>
</template>

<script setup lang="tsx">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { BaseNotifications } from "@modernary/mui/components/notifications";
import { useAppNav } from "@modernary/mui/components/common";

import { routeNames } from "@/routes";

const route = useRoute();
const { setNavigationItems, setApplicationTitleComponent } = useAppNav();

const titleComponent = () => <div class="ml-3 font-bold text-neutral-500 md:ml-4 md:text-xl">SMART</div>;
setApplicationTitleComponent(titleComponent);

// Nav items should be set one time during app initilization / config.
setNavigationItems([
  {
    label: "home",
    className: "",
    showLabel: false,
    items: [
      { label: "Home", name: routeNames.home },
      { label: "Complaints", name: routeNames.complaints },
      { label: "Contacts", name: routeNames.persons },
    ],
  },
  {
    label: "Solid Waste",
    className: "", //break-before-column
    items: [
      { label: "Applications", name: routeNames.applications },
      { label: "Disposal Reports", name: routeNames.disposalReports },
      { label: "Facilities", name: routeNames.facilitySites },
      { label: "Inspections", name: routeNames.inspections },
      { label: "Permits", name: routeNames.permits },
      { label: "Permit Versions", name: routeNames.permitVersions },
      { label: "Reports", name: routeNames.reports },
      {
        label: "Facility Data Export",
        name: routeNames.exportFacilityData,
      },
    ],
  },
  {
    label: "Sustainability",
    className: "",
    items: [
      { label: "Organizations", name: routeNames.organizations },
      { label: "Recycling Reports", name: routeNames.recyclingReports },
      { label: "SWMP", name: routeNames.swmps },
      {
        label: "Diverted Material Report",
        name: routeNames.divertedMaterialReport,
      },
      {
        label: "Material Category Report",
        name: routeNames.materialCategoryReport,
      },
      {
        label: "Organization Material Report",
        name: routeNames.organizationMaterialReport,
      },
      {
        label: "Recycled Material Report",
        name: routeNames.recycledMaterialReport,
      },
      {
        label: "Recycling Rate Report",
        name: routeNames.recyclingRateReport,
      },
      {
        label: "Recycling Report Data Export",
        name: routeNames.exportRecyclingReportData,
      },
      {
        label: "Email List Report",
        name: routeNames.emailList,
      },
      {
        label: "Generated Waste Report",
        name: routeNames.generatedWasteReport,
      },
      {
        label: "Disposal Report Data Export",
        name: routeNames.exportDisposalReportData,
      },
    ],
  },
]);
</script>
